<template>
  <b-modal
    v-model="visible"
    @hidden="close"
    size="lg"
    title="Update Request"
    title-tag="h3"
    modal-class="modal-primary"
    scrollable
  >
    <b-row>
      <b-col>
        <account-client-header
          :account="client.account"
          :client="client.name"
        />
      </b-col>
    </b-row>
    <br />
    <validation-observer ref="observer">
      <b-row>
        <b-col :cols="12" lg="6">
          <b-form-group label="Select request">
            <v-select
              :disabled="isCompleted || (!isCompleted && !isSpecialist)"
              v-model="requestSelected"
              :options="requestOptions"
              :reduce="(request) => request.id"
              label="text"
            />
          </b-form-group>
          <validation-provider rules="required" v-slot="{ errors }">
            <b-form-group label="Select email" v-if="requestSelected === 2">
              <v-select
                v-model="emailSelected"
                :disabled="isCompleted || (!isCompleted && !isSpecialist)"
                :options="client.email"
                :reduce="(email) => email.id"
                :class="errors[0] && validator ? 'border-danger' : ''"
                label="user"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col :cols="12" lg="6">
          <b-table
            :items="gItemsTable"
            :fields="fieldsTable"
            class="py-2"
            small
            responsive
          >
            <template #cell(file_name)="{ item }">
              <a :href="item.url" target="_blank" class="my-1">
                {{ item.file_name }}
              </a>
            </template>
            <template #cell(created_at)="{ item }">
              <span>{{ item.created_at | myGlobalWithHour }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <b-form-group label="Observation">
            <b-form-textarea disabled v-model="observation" rows="4" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isLetter">
        <b-col>
          <validation-provider
            :rules="!isCompleted ? 'required' : ''"
            v-slot="{ errors }"
          >
            <b-form-group label="Recomendation">
              <b-form-textarea
                :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                v-model="recomendation"
                :class="errors[0] && validator ? 'border-danger' : ''"
                rows="4"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <div v-else-if="isRecovery && hasOldData">
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group
                label="IN THIS CASE IF THE CUSTOMER DID NOT RECEIVE LETTER THESE ARE THE INDICATIONS:"
              >
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[0]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group
                label="INFORMATION WAS RECOVERED AND THE RESULTS ARE AS FOLLOWS:"
              >
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[1]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="CLIENT RECOMENDATIONS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[2]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="ADVISOR RECOMENDATIONS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[3]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="NEXT STEPS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[4]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="isRecovery && !hasOldData">
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="RESULTS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[0]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="COMMENTS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[1]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              :rules="!isCompleted ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-form-group label="NEXT STEPS:">
                <b-form-textarea
                  :disabled="!isSpecialist || (isSpecialist && isCompleted)"
                  :class="errors[0] && validator ? 'border-danger' : ''"
                  v-model="comments[2]"
                  rows="4"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <template #modal-footer>
      <b-row class="w-100 justify-content-between">
        <b-col :cols="8" class="mt-1 d-inline-flex" v-if="isCompleted">
          <h4 class="mt-1">DONE BY:</h4>
          <h4 class="mx-2">
            <b-badge variant="info" class="p-1">
              {{ modalData.userRecommendation }} ||
              {{ modalData.dateRecommendation | myGlobalWithHour }}
            </b-badge>
          </h4>
        </b-col>
        <b-col
          v-if="!isCompleted"
          :cols="isCompleted ? 4 : 12"
          class="d-flex justify-content-end"
        >
          <b-button
            @click="completed"
            v-if="isSpecialist"
            size="lg"
            variant="success"
          >
            Completed
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
//components
import AccountClientHeader from "@/views/commons/components/applications/views/components/others/AccountClientHeader.vue";
import VSelect from "vue-select";
//data
import { modalTableFields } from "../data/fields.data";
//vuex
import { mapGetters, mapActions } from "vuex";
//vee-validate
import { ValidationObserver, ValidationProvider } from "vee-validate";
//services
import DisputeResultService from "@/views/commons/components/dispute-results/services/dispute-results.service";

export default {
  created() {
    this.open();
  },
  components: {
    AccountClientHeader,
    VSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      visible: false,
      observation: "",
      recomendation: "",
      requestSelected: 0,
      requestOptions: [
        { id: 1, text: "LETTER" },
        { id: 2, text: "RECOVERY" },
      ],
      emailSelected: 0,
      emailOptions: [],
      comments: [],
      validator: false,
    };
  },
  methods: {
    async completed() {
      this.validator = true;
      let hasContent = await this.$refs.observer.validate();
      if (hasContent) {
        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const result = await DisputeResultService.updateDisputeResults({
            credential: this.emailSelected,
            dispute_id: this.client.id,
            observation0: this.recomendation ? this.recomendation : null,
            observation1: this.comments[0] ? this.comments[0] : null,
            observation2: this.comments[1] ? this.comments[1] : null,
            observation3: this.comments[2] ? this.comments[2] : null,
            observation4: this.comments[3] ? this.comments[3] : null,
            observation5: this.comments[4] ? this.comments[4] : null,
            type: this.requestSelected,
            user_id: this.currentUser.user_id,
          });
          if (result) {
            this.$emit("refreshTable");
            this.showSuccessSwal();
            this.close();
          } else {
            this.showErrorSwal();
          }
        }
      }
    },
    open() {
      this.fieldsTable = modalTableFields;
      this.requestSelected = this.modalData.request
        ? this.modalData.request
        : 0;
      this.emailSelected = this.client.meEmail ? this.client.meEmail : null;
      this.modalData.comments.map((el) => this.comments.push(el));
      this.observation = this.modalData.observation;
      this.recomendation = this.modalData.recomendation;
      if (this.requestSelected === 3) {
        this.requestOptions.push({ id: 3, text: "NCR EXPIRED" });
      }
      this.removePreloader();
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.$emit("closeMe");
    },
    ...mapActions({}),
  },
  computed: {
    isCompleted() {
      return !!this.$route.meta.status;
    },
    isSpecialist() {
      return this.moduleId === 28;
    },
    hasOldData() {
      return !!this.comments[5];
    },
    isLetter() {
      return this.modalData.request === 1;
    },
    isRecovery() {
      return this.modalData.request === 2;
    },
    ...mapGetters({
      client: "DebtSolutionDisputeResult/gClient",
      modalData: "DebtSolutionDisputeResult/gModalData",
      gItemsTable: "DebtSolutionDisputeResult/gItemsTable",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
