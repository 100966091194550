var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('filter-slot',{staticClass:"border-3 border-table-radius p-0 m-0",class:_vm.currentUser.modul_id == 5 ? 'border-top-info' : 'border-top-primary',attrs:{"filter":_vm.allfilters,"totalRows":_vm.totalRows,"paginate":_vm.paginate,"startPage":_vm.startPage,"toPage":_vm.toPage,"filterPrincipal":_vm.filters.filterPrincipal},on:{"reload":function($event){return _vm.$refs['disputeResultsList'].refresh()},"onChangeCurrentPage":_vm.onChangeCurrentPage,"reset-all-filters":_vm.refreshTable},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"disputeResultsList",staticClass:"m-0 p-0 w-100",attrs:{"items":_vm.dataProvider,"fields":_vm.visibleFields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy,"sort-direction":_vm.order,"show-empty":"","sticky-header":'70vh',"responsive":""},on:{"update:busy":function($event){_vm.isBusy=$event},"sort-changed":_vm.changeStatusOrder},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"4px"}},[(_vm.moduleId != 22)?_c('router-link',{attrs:{"to":{
                name:
                  _vm.moduleId == 5
                    ? 'debt-solution-dashboard'
                    : _vm.moduleId == 7
                    ? 'boost-credit-dashboard'
                    : _vm.moduleId == 6
                    ? 'credit-experts-dashboard'
                    : _vm.moduleId == 20
                    ? 'connection-clients-account'
                    : _vm.moduleId == 28
                    ? 'specialists-client-dashboard'
                    : null,
                params: { idClient: ("" + (data.item.account_id)) },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.client_name)+" ")]):_vm._e(),(
                _vm.moduleId == 22 &&
                (_vm.currentUser.role_id === 1 ||
                  _vm.currentUser.role_id === 17 ||
                  _vm.currentUser.role_id === 2 ||
                  _vm.currentUser.role_id === 14)
              )?_c('router-link',{attrs:{"to":{
                name: 'ce-customer-service-dashboard',
                params: {
                  idClient: data.item.account_id,
                  hasAccess: true, // is alert
                  typeAccess: 6, // Welcome,
                  wayAccess: 4, // enter by modal
                },
              }}},[_vm._v(" "+_vm._s(data.item.client_name)+" ")]):_vm._e(),([6, 20, 21, 22].includes(data.item.module_id))?_c('b-badge',{staticClass:"font10 ml-1",attrs:{"variant":data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'}},[_vm._v(" "+_vm._s(data.item.name_type_client)+" ")]):_vm._e()],1),_c('status-account',{attrs:{"account":data.item,"text":true}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(data.item.client_account)+" ")])])]}},{key:"cell(program)",fn:function(data){return [_c('center',[_c('span',[_vm._v(_vm._s(data.item.program))])])]}},{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"text-primary cursor-pointer font-weight-bolder",on:{"click":function($event){return _vm.openModalAddRound(data.item)}}},[_vm._v(" "+_vm._s([1, 2].includes(data.item.type_round) ? "FIRST ROUND" : "-")+" ")])]}},{key:"cell(user_created)",fn:function(data){return [_c('b-col',[_c('b-row',[_c('span',[_vm._v(" "+_vm._s(data.item.user_update)+" ")])]),_c('b-row',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.updated_at)))])])],1)]}},{key:"cell(userrecommendation)",fn:function(data){return [_c('b-col',[_c('b-row',[_c('span',[_vm._v(" "+_vm._s(data.item.userrecommendation)+" ")])]),_c('b-row',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.created_recommendation)))])])],1)]}},{key:"cell(recommended_services)",fn:function(ref){
              var item = ref.item;
return [(!_vm.displayedServices(item))?_c('b-badge',{attrs:{"variant":"light-danger"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-5"},[_c('feather-icon',{attrs:{"icon":"SlashIcon"}}),_c('span',{staticClass:"lh-0"},[_vm._v(" None ")])],1)]):_c('b-badge',{attrs:{"variant":"light-info"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-5"},[_c('feather-icon',{attrs:{"icon":"GridIcon"}}),_c('span',{staticClass:"lh-0"},[_vm._v(" "+_vm._s(_vm.displayedServices(item).length)+" services ")])],1)])]}},{key:"cell(status)",fn:function(data){return [_c('center',[_c('b-badge',{staticClass:"w-75",class:data.item.status_dispute ? 'text-success' : 'text-warning',attrs:{"pill":"","variant":data.item.status_dispute ? 'light-success' : 'light-warning'}},[_vm._v(" "+_vm._s(data.item.status)+" ")])],1)]}},{key:"cell(last_score_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(data.item.last_score_date))+" ")]}},{key:"cell(read)",fn:function(data){return [_c('center',[_c('b-badge',{class:data.item.read ? 'text-danger' : 'text-success',attrs:{"pill":"","variant":data.item.read ? 'light-danger' : 'light-success'}},[_vm._v(" "+_vm._s(_vm._f("statusDisputeResultsRead")(data.item.read))+" ")])],1)]}},{key:"cell(request)",fn:function(ref){
              var item = ref.item;
return [_c('center',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Completed Update Request'),expression:"'Completed Update Request'",modifiers:{"hover":true,"right":true}}],staticClass:"text-primary cursor-pointer mr-1",attrs:{"size":"20","icon":"EyeIcon"},on:{"click":function($event){return _vm.openActionModal(item)}}}),_c('tabler-icon',{staticClass:"cursor-pointer",staticStyle:{"color":"var(--warning)"},attrs:{"icon":"FoldersIcon","size":"30"},on:{"click":function($event){return _vm.openModalRequest(item)}}})],1)]}},{key:"cell(tracking)",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info cursor-pointer",attrs:{"size":"20","icon":"ListIcon"},on:{"click":function($event){return _vm.openUrModalTracking(item)}}})],1)]}},{key:"cell(update_file)",fn:function(ref){
              var item = ref.item;
return [_vm._l((JSON.parse(item.files)),function(data,index){return [_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.file_name),expression:"data.file_name",modifiers:{"hover":true,"top":true}}],key:index,attrs:{"href":data.url,"target":"_blank"}},[_c('amg-icon',{key:index,staticClass:"cursor-pointer mt-0 mr-0 bigger text-danger text-center",staticStyle:{"height":"18px","width":"18px","margin-bottom":"3px"},attrs:{"icon":"FilePdfIcon"}})],1)]})]}},{key:"cell(type)",fn:function(data){return [_c('b-badge',{staticClass:"w-100 text-center d-flex justify-content-center",attrs:{"variant":data.item.type == 1 ? 'light-success' : 'light-warning'}},[_vm._v(" "+_vm._s(data.item.type == 1 ? "LETTER" : "RECOVERY")+" ")])]}}])})]},proxy:true}])}),(_vm.activeModalRequest)?_c('modal-request',{on:{"closeMe":_vm.closeModalRequest}}):_vm._e(),(_vm.modalAddRoundLetterState)?_c('add-round-modal',{attrs:{"data-round":_vm.itemClient,"validate-modal":_vm.validateModal,"show-all-letters":false},on:{"close":function($event){_vm.modalAddRoundLetterState = false}}}):_vm._e(),(_vm.showUrModalTracking)?_c('RequestUpdateTracking',{attrs:{"dataUr":_vm.dataUr},on:{"close":function($event){_vm.showUrModalTracking = false}}}):_vm._e(),(_vm.showModalAction)?_c('ActionDoneReturnModal',{attrs:{"dataClient":_vm.dataUpdate,"revision":_vm.revision,"section":'UPDATE'},on:{"close":function($event){_vm.showModalAction = false},"reload":_vm.refreshTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }