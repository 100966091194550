var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","title":"Update Request","title-tag":"h3","modal-class":"modal-primary","scrollable":""},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-row',{staticClass:"w-100 justify-content-between"},[(_vm.isCompleted)?_c('b-col',{staticClass:"mt-1 d-inline-flex",attrs:{"cols":8}},[_c('h4',{staticClass:"mt-1"},[_vm._v("DONE BY:")]),_c('h4',{staticClass:"mx-2"},[_c('b-badge',{staticClass:"p-1",attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(_vm.modalData.userRecommendation)+" || "+_vm._s(_vm._f("myGlobalWithHour")(_vm.modalData.dateRecommendation))+" ")])],1)]):_vm._e(),(!_vm.isCompleted)?_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":_vm.isCompleted ? 4 : 12}},[(_vm.isSpecialist)?_c('b-button',{attrs:{"size":"lg","variant":"success"},on:{"click":_vm.completed}},[_vm._v(" Completed ")]):_vm._e()],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-row',[_c('b-col',[_c('account-client-header',{attrs:{"account":_vm.client.account,"client":_vm.client.name}})],1)],1),_c('br'),_c('validation-observer',{ref:"observer"},[_c('b-row',[_c('b-col',{attrs:{"cols":12,"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Select request"}},[_c('v-select',{attrs:{"disabled":_vm.isCompleted || (!_vm.isCompleted && !_vm.isSpecialist),"options":_vm.requestOptions,"reduce":function (request) { return request.id; },"label":"text"},model:{value:(_vm.requestSelected),callback:function ($$v) {_vm.requestSelected=$$v},expression:"requestSelected"}})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.requestSelected === 2)?_c('b-form-group',{attrs:{"label":"Select email"}},[_c('v-select',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":_vm.isCompleted || (!_vm.isCompleted && !_vm.isSpecialist),"options":_vm.client.email,"reduce":function (email) { return email.id; },"label":"user"},model:{value:(_vm.emailSelected),callback:function ($$v) {_vm.emailSelected=$$v},expression:"emailSelected"}})],1):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":12,"lg":"6"}},[_c('b-table',{staticClass:"py-2",attrs:{"items":_vm.gItemsTable,"fields":_vm.fieldsTable,"small":"","responsive":""},scopedSlots:_vm._u([{key:"cell(file_name)",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"my-1",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.file_name)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.created_at)))])]}}])})],1)],1),_c('br'),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Observation"}},[_c('b-form-textarea',{attrs:{"disabled":"","rows":"4"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)],1)],1),(_vm.isLetter)?_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Recomendation"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.recomendation),callback:function ($$v) {_vm.recomendation=$$v},expression:"recomendation"}})],1)]}}],null,false,2710079855)})],1)],1):(_vm.isRecovery && _vm.hasOldData)?_c('div',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"IN THIS CASE IF THE CUSTOMER DID NOT RECEIVE LETTER THESE ARE THE INDICATIONS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[0]),callback:function ($$v) {_vm.$set(_vm.comments, 0, $$v)},expression:"comments[0]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"INFORMATION WAS RECOVERED AND THE RESULTS ARE AS FOLLOWS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[1]),callback:function ($$v) {_vm.$set(_vm.comments, 1, $$v)},expression:"comments[1]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CLIENT RECOMENDATIONS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[2]),callback:function ($$v) {_vm.$set(_vm.comments, 2, $$v)},expression:"comments[2]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ADVISOR RECOMENDATIONS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[3]),callback:function ($$v) {_vm.$set(_vm.comments, 3, $$v)},expression:"comments[3]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NEXT STEPS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[4]),callback:function ($$v) {_vm.$set(_vm.comments, 4, $$v)},expression:"comments[4]"}})],1)]}}])})],1)],1)],1):(_vm.isRecovery && !_vm.hasOldData)?_c('div',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"RESULTS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[0]),callback:function ($$v) {_vm.$set(_vm.comments, 0, $$v)},expression:"comments[0]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"COMMENTS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[1]),callback:function ($$v) {_vm.$set(_vm.comments, 1, $$v)},expression:"comments[1]"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":!_vm.isCompleted ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NEXT STEPS:"}},[_c('b-form-textarea',{class:errors[0] && _vm.validator ? 'border-danger' : '',attrs:{"disabled":!_vm.isSpecialist || (_vm.isSpecialist && _vm.isCompleted),"rows":"4"},model:{value:(_vm.comments[2]),callback:function ($$v) {_vm.$set(_vm.comments, 2, $$v)},expression:"comments[2]"}})],1)]}}])})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }