<template>
  <div>
    <header-slot v-if="currentUser.modul_id !== 5" />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-solution-specialist-dispute-results-in-process' }"
        exact
        :exact-active-class="status === 0 ? 'active' : ''"
        :link-classes="[
          currentUser.modul_id == 5 ? 'sub-tab-nav' : '',
          'px-3',
          bgTabsNavs,
        ]"
        >IN PROCESS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debt-solution-specialist-dispute-results-completed' }"
        exact
        :exact-active-class="status === 1 ? 'active' : ''"
        :link-classes="[
          currentUser.modul_id == 5 ? 'sub-tab-nav' : '',
          'px-3',
          bgTabsNavs,
        ]"
        >COMPLETED
      </b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//components
import TableInProcess from "@/views/commons/components/dispute-results/views/components/TableInProcess.vue";
import TableCompleted from "@/views/commons/components/dispute-results/views/components/TableCompleted.vue";

export default {
  components: {
    TableInProcess,
    TableCompleted,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    status() {
      return this.$route.meta.status;
    },
  },
};
</script>