const inProcessFilters = {
  filterPrincipal:{
    type:'input',
    inputType:'text',
    placeHolder:'Client...',
    model: '',
  },
}
const completedFilters = {
  filterPrincipal:{
    type:'input',
    inputType:'text',
    placeHolder:'Client...',
    model: '',
  },
}

export {inProcessFilters,completedFilters}

