<template>
  <b-modal
    @hidden="closeModal()"
    v-model="onControl"
    centered
    modal-class="custom-modal-amg"
    size="caro"
    hide-footer
    title-class="h1 text-white fw-bold"
    no-close-on-esc
    no-close-on-backdrop
    no-enforce-focus
  >
    <template #modal-title>
      <tabler-icon icon="ReplaceIcon" class="text-white" size="22" />
      Update Request steps
    </template>
    <b-row>
      <b-col sm="12">
        <HeaderInformation :dataWorkPlan="rowData" />
      </b-col>
      <b-col sm="12">
        <step-progress
          :steps="steps"
          :currentStep="currentStep"
          :icons="icons"
          @step-changed="handleStepChange"
          @step-prev="handleStepPrev"
          :validationNext="validateStepNext"
          :validationPrev="validateStepPrev"
        >
          <template v-slot:default="{ step }">
            <div v-if="step === 1">
              <FirstStep
                ref="firstStep"
                :client="rowData"
                :section="'update'"
              />
            </div>
            <div v-else-if="step === 2">
              <b-card>
                 <ThirdStep
                  ref="thirdStep"
                  :rowData="rowData"
                  :section="'update'"
                />
              </b-card>
            </div>
            <div v-else-if="step === 3">
              <FourthStep
                ref="fourthStep"
                :dataClient="rowData"
                :section="'update'"
              />
            </div>
            <div v-else-if="step === 4">
              <FifthStep
                ref="fifthStep"
                :section="'update'"
                :rowId="rowData.id"
              />
            </div>
          </template>
        </step-progress>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import FirstStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FirstStep.vue";
import FourthStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FourthStep.vue";
import ThirdStep from "@/views/commons/components/ncr/components/report/DisputeReport.vue";
import FifthStep from "@/views/commons/components/request-workplan/views/components/modal/steps/FifthStep.vue";
import StepProgress from "@/views/commons/steps/StepProgress.vue";
import HeaderInformation from "@/views/commons/components/request-workplan/views/components/modal/HeaderInformation.vue";
import SynchronizationOfItems from "@/views/commons/components/request-workplan/views/components/modal/steps/syncronization/SynchronizationOfItems.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    rowData: {
      required: true,
      type: Object,
      default: {},
    },
  },
  components: {
    FirstStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    StepProgress,
    HeaderInformation,
    SynchronizationOfItems,
  },
  data() {
    return {
      onControl: false,
      steps: ["UPDATE ITEMS", "DISPUTE", "RECOMMEND", "TO REVIEW"],
      icons: ["UpdatedIcon", "DisputeIcon", "RecommendIcon", "CheckIcon"],
      prevButtonText: ["Prev", "Prev", "Prev", "Prev", "Prev"],
      nextButtonText: ["Next", "Next", "Next", "Next", "Next"],
      shouldUpdateStep: true,
    };
  },
  async created() {
    await this.A_ID_UPDATE_REQUEST(this.rowData.id);
    this.onControl = true;
    this.fetchStepWorkPlan();
  },
  methods: {
    ...mapActions("DebtSolutionDisputeResult", [
      "A_STEP_UR",
      "A_IS_EXECUTING_METHOD",
      "A_UPDATE_STEP_UR",
    ]),
    ...mapActions({
      A_ID_UPDATE_REQUEST: "RequestWorkPlanStore/A_ID_UPDATE_REQUEST",
    }),
    fetchStepWorkPlan() {
      this.A_STEP_UR(this.rowData.id);
    },
    async closeModal() {
      await this.A_ID_UPDATE_REQUEST(null);
      this.$emit("close");
    },
    async handleChildStepChange(step) {
      switch (step) {
        case 1:
          return await this.$refs.firstStep.updateStatus();
        case 2:
          return await this.$refs.thirdStep.toRevision();
        case 3:
          return await this.$refs.fourthStep.save();
        case 4:
          return await this.$refs.fifthStep.completeAction(this.rowData);
        default:
          return true;
      }
    },

    async handleStepChange(step) {
      try {
        const adjustedStep = step - 1;
        const result = await this.handleChildStepChange(adjustedStep);
        if (result) {
          await this.updateStepWorkPlan(step);
        } else {
          console.error("Error occurred, unable to proceed to next step");
        }
        this.$emit("reload");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },

    async handleChildStepPrev(step) {
      switch (step) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return await this.$refs.fifthStep.returnAction(this.rowData.id);
        case 4:
          return false;
        default:
          return true;
      }
    },

    async handleStepPrev(step) {
      try {
        const result = await this.handleChildStepPrev(step);
        if (result) {
          if (!this.G_IS_EXECUTING_METHOD) {
            await this.updateStepWorkPlan(step);
          }
        } else {
          console.error("Error occurred, unable to go back to previous step");
        }
        this.$emit("reload");
      } catch (error) {
        console.error("Error occurred:", error);
      } finally {
        this.A_IS_EXECUTING_METHOD(false);
      }
    },
    updateStepWorkPlan(step) {
      const updatedData = {
        id: this.rowData.id,
        step: step,
      };
      this.A_UPDATE_STEP_UR(updatedData);
    },
    updateProcess(newProcess) {
      this.$set(this.rowData, "process", newProcess);
    },
  },
  computed: {
    ...mapGetters({
      G_IS_EXECUTING_METHOD: "DebtSolutionDisputeResult/G_IS_EXECUTING_METHOD",
      currentStep: "DebtSolutionDisputeResult/G_STEP_UR",
    }),
    validateStepNext() {
      switch (this.currentStep) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        case 4:
          return this.isSupervisor || this.isChief || this.isCeo;
        default:
          return true;
      }
    },
    validateStepPrev() {
      switch (this.currentStep) {
        case 1:
          return false;
        case 2:
          return this.rowData.type_client == 1 ? false : true;
        case 3:
          return false;
        case 4:
          return this.isSupervisor || this.isChief || this.isCeo;
        case 5:
          return false;
        default:
          return true;
      }
    },
  },
};
</script>

<style lang="scss">
.fw-bold {
  font-weight: bold !important;
}
</style>
