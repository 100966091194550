const inProcessFields = [
  {
    key: "lead_name",
    label: "Client",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "title",
    label: "Title Round",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "last_score_date",
    label: "Last CR Date",
    thClass: ["text-left"],
    visible: true,
  },
  // {
  //   key: "cr",
  //   label: "CR",
  //   thClass: ["text-center"],
  //   tdClass: ["text-center"],
  //   visible: false,
  // },
  {
    key: "step",
    label: "process",
    thClass: ["text-center"],
    tdClass: ["text-center"],
    visible: true,
  },
  {
    key: "user_created",
    label: "Request by",
    thClass: ["text-left"],
    sortable: true,
    visible: true,
  },
  {
    key: "specialist_assign",
    label: "Specialist",
    class: "text-center",
    visible: true,
  },
  {
    key: "type",
    label: "Type Request",
    visible: true,
  },
  {
    key: "update_file",
    label: "Update File",
    visible: true,
    class: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "request",
    label: "Actions",
    thClass: ["text-center"],
    visible: true,
  },
];

const completedFields = [
  {
    key: "lead_name",
    label: "Client",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "title",
    label: "Title Round",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "last_score_date",
    label: "Last CR Date",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "user_created",
    label: "Updated by",
    thClass: ["text-left"],
    sortable: true,
    visible: true,
  },
  {
    key: "userrecommendation",
    label: "Completed by",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "recommended_services",
    label: "recommendations",
    class: "text-center",
    visible: true,
  },
  {
    key: "read",
    label: "Read",
    thClass: ["text-center"],
    visible: true,
  },
  {
    key: "type",
    label: "Type Request",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "request",
    label: "Actions",
    thClass: ["text-center"],
    visible: true,
  },
];

const deletedFields = [
  {
    key: "lead_name",
    label: "Client",
    thClass: ["text-left"],
  },
  {
    key: "title",
    label: "Title Round",
    thClass: ["text-left"],
  },
  {
    key: "last_score_date",
    label: "Last CR Date",
    thClass: ["text-left"],
  },
  // {
  //   key: "cr",
  //   label: "CR",
  //   thClass: ["text-left"],
  // },
  {
    key: "user_created",
    label: "Request by",
    thClass: ["text-left"],
    sortable: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },

  {
    key: "deleted_by",
    label: "Deleted By",
    thClass: ["text-left"],
  },

  // {
  //   key:"request",
  //   label:'Request',
  //   thClass:['text-center'],
  // }
];

const toRevisionFields = [
  {
    key: "lead_name",
    label: "Client",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "title",
    label: "Title Round",
    thClass: ["text-left"],
  },
  {
    key: "last_score_date",
    label: "Last CR Date",
    thClass: ["text-left"],
    visible: true,
  },
  {
    key: "user_created",
    label: "Updated by",
    thClass: ["text-left"],
    sortable: true,
    visible: true,
  },
  {
    key: "step",
    label: "process",
    thClass: ["text-center"],
    tdClass: ["text-center"],
    visible: true,
  },
  {
    key: "recommended_services",
    label: "recommendations",
    class: "text-center",
    visible: true
  },
  {
    key: "read",
    label: "Read",
    thClass: ["text-center"],
    visible: true,
  },
  {
    key: "type",
    label: "Type Request",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "update_file",
    label: "Update File",
    visible: true,
    class: "text-center",
  },
  {
    key: "actions",
    label: "Action",
    visible: true,
  },
];

const modalTableFields = [
  {
    key: "file_name",
    label: "Name",
  },
  {
    key: "size",
    label: "Size",
  },
  {
    key: "created_at",
    label: "Updated at",
  },
];

export {
  inProcessFields,
  completedFields,
  deletedFields,
  modalTableFields,
  toRevisionFields,
};
