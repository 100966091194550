<template>
  <b-container fluid class="p-0">
    <filter-slot
      class="border-3 border-table-radius p-0 m-0"
      :class="
        currentUser.modul_id == 5 ? 'border-top-info' : 'border-top-primary'
      "
      :filter="allfilters"
      :totalRows="totalRows"
      :paginate="paginate"
      :startPage="startPage"
      :toPage="toPage"
      :filterPrincipal="filters.filterPrincipal"
      @reload="$refs['disputeResultsList'].refresh()"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reset-all-filters="refreshTable"
    >
      <template #buttons>
        <div>
          <b-button
            v-if="isSpecialist && hasPermission"
            @click="assignUpdate"
            class="ml-2"
            variant="outline-success"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              Assigning...<b-spinner small variant="success" />
            </span>
            <span v-else>Assign Update</span>
          </b-button>
        </div>
      </template>
      <template #table>
        <b-table
          class="m-0 p-0 w-100"
          ref="disputeResultsList"
          :items="dataProvider"
          :fields="visibleFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          :sort-direction="order"
          @sort-changed="changeStatusOrder"
          show-empty
          :sticky-header="'70vh'"
          responsive
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(lead_name)="data">
            <span class="d-flex align-items-center" style="gap: 4px">
              <router-link
                v-if="moduleId != 22"
                :to="{
                  name:
                    moduleId == 5
                      ? 'debt-solution-dashboard'
                      : moduleId == 7
                      ? 'boost-credit-dashboard'
                      : moduleId == 6
                      ? 'credit-experts-dashboard'
                      : moduleId == 20
                      ? 'connection-clients-account'
                      : moduleId == 28
                      ? 'specialists-client-dashboard'
                      : null,
                  params: { idClient: `${data.item.account_id}` },
                }"
                target="_blank"
              >
                {{ data.item.client_name }}
              </router-link>

              <router-link
                v-if="
                  moduleId == 22 &&
                  (currentUser.role_id === 1 ||
                    currentUser.role_id === 17 ||
                    currentUser.role_id === 2 ||
                    currentUser.role_id === 14)
                "
                :to="{
                  name: 'ce-customer-service-dashboard',
                  params: {
                    idClient: data.item.account_id,
                    hasAccess: true, // is alert
                    typeAccess: 6, // Welcome,
                    wayAccess: 4, // enter by modal
                  },
                }"
              >
                {{ data.item.client_name }}
              </router-link>
              <b-badge
                class="font10 ml-1"
                :variant="
                  data.item.name_type_client == 'DIGITAL' ? 'info' : 'primary'
                "
                v-if="[6, 20, 21, 22].includes(data.item.module_id)"
              >
                {{ data.item.name_type_client }}
              </b-badge>
            </span>
            <status-account :account="data.item" :text="true" />
            <div class="d-flex align-items-center">
              <span class="d-block">
                {{ data.item.client_account }}
              </span>
            </div>
            <b-badge
              v-if="data.item.status == 'RETURNED'"
              variant="warning"
              class="ml-1"
              >{{ data.item.status }}</b-badge
            >
          </template>
          <template #cell(program)="data">
            <center>
              <span>{{ data.item.program }}</span>
            </center>
          </template>
          <template #cell(title)="data">
            <div
              class="text-primary cursor-pointer font-weight-bolder"
              @click="openModalAddRound(data.item)"
            >
              {{ [1, 2].includes(data.item.type_round) ? 'FIRST ROUND' : '-' }}
            </div>
          </template>
          <template #cell(last_score_date)="data">
            {{ data.item.last_score_date | myGlobalDay }}
            <div>
              <b-badge
                :variant="
                  validateDate(data.item.last_score_date)
                    ? 'light-success'
                    : 'light-danger'
                "
                >{{
                  validateDate(data.item.last_score_date)
                    ? "CR CURRENT"
                    : "CR EXPIRED"
                }}</b-badge
              >
            </div>
          </template>

          <template #cell(step)="{ item }">
            <div class="d-flex flex-column align-items-center">
              <amg-icon
                @click="openModalSteps(item)"
                size="25"
                :icon="iconsSteps[item.current_step - 1]"
                class="text-primary cursor-pointer"
              />
              <b-badge variant="dark">{{
                steps[item.current_step - 1]
              }}</b-badge>
            </div>
          </template>
          <template #cell(user_created)="data">
            <b-col>
              <b-row
                ><span> {{ data.item.user_created }} </span></b-row
              >
              <b-row
                ><span>{{
                  data.item.create_dispute | myGlobalWithHour
                }}</span></b-row
              >
            </b-col>
          </template>
          <template #cell(status)="data">
            <center>
              <b-badge
                pill
                :variant="
                  data.item.status === 'COMPLETED'
                    ? 'light-success'
                    : 'light-warning'
                "
                :class="
                  data.item.status === 'COMPLETED'
                    ? 'text-success'
                    : 'text-warning'
                "
                class="w-75"
              >
                {{ data.item.status }}
              </b-badge>
            </center>
          </template>
          <template #cell(request)="{ item }">
            <div class="text-center">
              <feather-icon
                v-if="moduleId == 28 && !validateDate(item.last_score_date)"
                icon="PlusCircleIcon"
                size="18"
                class="cursor-pointer text-info ml-1"
                variant="info"
                @click="createReport(item)"
              />
              <feather-icon
                icon="TrashIcon"
                size="18"
                class="cursor-pointer text-danger ml-1"
                variant="danger"
                @click="deletedUpdateRequest(item.id)"
              />
            </div>
          </template>
          <template #cell(specialist_assign)="{ item }">
            <div v-if="isSpecialist">
              <feather-icon
                v-if="!item.specialist_assign"
                @click="openModalAssign(item)"
                size="16"
                icon="UserPlusIcon"
                class="mr-50 text-success cursor-pointer"
              />
              <span v-if="item.specialist_assign" class="d-block">{{
                item.specialist_assign
              }}</span>
            </div>
            <div v-else>
              <span v-if="item.specialist_assign">{{
                item.specialist_assign
              }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template #cell(tracking)="{ item }">
            <div>
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                @click="openUrModalTracking(item)"
                size="20"
                icon="ListIcon"
              />
            </div>
          </template>
          <template #cell(type)="data">
            <b-badge
              class="w-100 text-center d-flex justify-content-center"
              :variant="data.item.type == 1 ? 'light-success' : 'light-warning'"
            >
              {{ data.item.type == 1 ? "LETTER" : "RECOVERY" }}
            </b-badge>
          </template>
          <template #cell(update_file)="{ item }">
            <template v-for="(data, index) in JSON.parse(item.files)">
              <a
                :key="index"
                :href="data.url"
                target="_blank"
                v-b-tooltip.hover.top="data.file_name"
              >
                <amg-icon
                  :key="index"
                  icon="FilePdfIcon"
                  style="height: 18px; width: 18px; margin-bottom: 3px"
                  class="cursor-pointer mt-0 mr-0 bigger text-danger text-center"
              /></a>
            </template>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-request
      v-if="activeModalRequest"
      @closeMe="closeModalRequest"
      @refreshTable="refreshTable"
    />

    <modal-update-request
      v-if="modalData.state"
      :modalData="modalData"
      :statuss="this.statuss"
      :idround="this.idround"
      :client_account="this.client_account"
      :nameClient="this.nameClient"
      :credential="this.credential"
      :typee="this.typee"
      :account_id="this.account_id"
      :type_charge="this.type_charge"
      :option_recommendations="this.option_recommendations"
      :recommen="this.recommen"
      :observationData="this.observation"
    ></modal-update-request>

    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
    <AssignSpecificModal
      v-if="showModalAssign"
      :item="selectedItem"
      :section="section"
      @close="closeModalAssign"
      @refresh="refreshTable"
    />
    <RequestUpdateTracking
      v-if="showUrModalTracking"
      :dataUr="dataUr"
      @close="showUrModalTracking = false"
    />
    <StepsCreditReport
      v-if="showModalSteps"
      @close="showModalSteps = false"
      :rowData="rowData"
      @reload="refreshTable"
    />
  </b-container>
</template>
<script>
//components
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import AssignSpecificModal from "@/views/commons/components/request-workplan/views/components/modal/AssignSpecificModal.vue";
import RequestUpdateTracking from "@/views/commons/components/dispute-results/views/components/modal/RequestUpdateTracking.vue";
import StepsCreditReport from "@/views/commons/components/dispute-results/views/components/StepsCreditReport.vue";

import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import ModalRequest from "./ModalRequest.vue";
import IconStatusAccount from "@/views/commons/components/applications/views/components/icons/IconStatusAccount.vue";
import ModalUpdateRequest from "@/views/specialist-digital/views/updates/components/modal/ModalUpdateRequest.vue";
import DataFilters from "@/views/specialist-digital/views/updates/data/filters.data.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
//vuex
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
//data
import { inProcessFilters } from "../data/filters.data";
import { inProcessFields } from "../data/fields.data";

//services
import updateRequestService from "@/views/commons/components/dispute-results/services/dispute-results.service.js";
import UpdateService from "@/views/specialist-digital/views/updates/services/update.service.js";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  created() {
    this.start();
  },
  components: {
    FilterSlot,
    ModalRequest,
    ModalUpdateRequest,
    AddRoundModal,
    IconStatusAccount,
    AssignSpecificModal,
    RequestUpdateTracking,
    StatusAccount,
    StepsCreditReport,
  },
  data() {
    return {
      allfilters: DataFilters,
      inProcessFields,
      isBusy: false,
      filters: {},
      activeModalRequest: false,
      order: "asc",
      dataEdit: {},
      modalData: {
        state: false,
        openModal: true,
      },
      statuss: "",
      idround: "",
      client_account: "",
      nameClient: "",
      account_id: "",
      type_charge: null,
      option_recommendations: [],
      recommen: "",
      observation: "",

      typee: "",
      credential: "",
      itemClient: {},
      validateModal: 3,
      modalAddRoundLetterState: false,
      showModalAssign: false,
      selectedItem: null,
      section: null,
      isLoading: false,
      showUrModalTracking: false,
      dataUr: null,
      monthValidateNcr: 12,
      showModalSteps: false,
      iconsSteps: ["UpdatedIcon", "DisputeIcon", "RecommendIcon", "CheckIcon"],
      steps: ["UPDATE ITEMS", "DISPUTE", "RECOMMEND", "TO REVIEW"],
      rowData: [],
    };
  },
  methods: {
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    async dataProvider() {
      await this.fillInProcessData({
        advisorid: null,
        from: this.getFilterByKey("from").model,
        name_text: this.filters.filterPrincipal.model,
        order: this.order,
        orderby: "create_dispute",
        program: this.gProgram(this.moduleId).value,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        roleid: this.currentUser.role_id,
        status: 1,
        to: this.getFilterByKey("to").model,
        userid: this.currentUser.user_id,
        typee: this.getFilterByKey("status").model,
        f_specialist: null,
        is_deleted: 0,
        is_digital: this.isDigital,
        module_id: this.moduleId,
        specialist_id: this.isCreditAnalyst
          ? this.currentUser.user_id
          : this.getFilterByKey("specialist").model,
      });
      return this.gInProcessItems;
    },
    getFilterByKey(key) {
      return this.allfilters.find((filter) => filter.key === `${key}`);
    },

    async getCreditAnalyst() {
      let result = [];
      // result = await UpdateService.getUsersProgramsspNcr(params); Service Old
      result = await UpdateService.getCreditAnalyst();
      this.users = result.data;
      let newList = this.users.map(
        (s) => (
          { label: "All", value: 0 }, { label: s.specialist, value: s.id }
        )
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.getFilterByKey("specialist").options = newList;
    },

    start() {
      this.filters = inProcessFilters;
      this.getCreditAnalyst();
      this.filterColumn("cr", this.isSpecialist);
      this.filterColumn("specialist_assign", this.hasPermission);
      this.filterColumn("request", this.isSpecialist);
    },
    refreshTable() {
      this.$refs.disputeResultsList.refresh();
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async openModalRequest(row) {
      if (this.$route.meta.module != 11) {
        this.addPreloader();
        this.M_SET_CLIENT({
          name: row.client_name,
          id: row.id,
          client_account: row.client_account,
          account_id: row.account_id,
          meEmail: row.id_credential,
        });
        this.M_SET_MODAL_DATA({
          request: row.type,
          observation: row.observation,
          recomendation: row.recomendation,
          userRecommendation: row.userrecommendation,
          createdRecommendation: row.created_recommendation,
          dateRecommendation: row.created_recommendation,
          comments: [
            row.commentary1,
            row.commentary2,
            row.commentary3,
            row.commentary4,
            row.commentary5,
          ],
        });
        await this.fillDataTableModal({ id: row.id });
        if (row.type === 2)
          await this.setEmailModal({ client_id: row.account_id });
        this.activeModalRequest = true;
      } else {
        //open modal
        this.modalData.state = true;
        this.modalData.openModal = true;

        this.idround = row.id;
        this.account_id = row.client_account_id;
        this.client_account = row.client_account;
        this.nameClient = row.client_name;
        this.title = row.title;
        this.format = row.format;
        this.option_recommendations = row.option_recommendation;
        this.typee = 2;

        this.clientData = {
          dob: row.dob,
          address: row.address,
          ssn: row.ssn,
          origin_country: row.origin_country,
        };
      }
    },
    closeModalRequest() {
      this.activeModalRequest = false;
    },
    ...mapMutations({
      M_SET_CLIENT: "DebtSolutionDisputeResult/M_SET_CLIENT",
      M_SET_MODAL_DATA: "DebtSolutionDisputeResult/M_SET_MODAL_DATA",
      M_CHANGE_STATUS_ORDER: "DebtSolutionDisputeResult/M_CHANGE_STATUS_ORDER",
    }),
    ...mapActions({
      fillInProcessData: "DebtSolutionDisputeResult/fillInProcessData",
      fillDataTableModal: "DebtSolutionDisputeResult/fillDataTableModal",
      setEmailModal: "DebtSolutionDisputeResult/setEmailModal",
      A_REQUEST_NCR_COUNTERS: "RequestNcrStore/A_REQUEST_NCR_COUNTERS",
      CHANGE_REFRESH_TABLE: "DebtSolutionDisputeResult/CHANGE_REFRESH_TABLE",
    }),
    async deletedUpdateRequest(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const params = {
            id_dispute: id,
            user_id: this.currentUser.user_id,
          };
          const response = await updateRequestService.deletedUpdateRequest(
            params
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (error) {
        console.error("Error tring to delete a Update Request");
      }
    },
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        client_account: data.client_account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: {
          id: data.letter_id,
          title: data.title,
          format: data.format,
        },
      };
      this.modalAddRoundLetterState = true;
    },
    async assignUpdate() {
      this.isLoading = true;
      const params = {
        section: "UPDATE_REQUEST",
      };
      const data = await RequestWorkPlanService.assignWorkplan(params);
      if (data.status == 200) {
        this.isLoading = false;
        this.$refs.disputeResultsList.refresh();
      }
    },
    openModalAssign(item) {
      this.selectedItem = item;
      this.section = "UPDATE_REQUEST";
      this.showModalAssign = true;
    },

    closeModalAssign() {
      this.showModalAssign = false;
    },
    async createReport(item) {
      const confirm = await this.showConfirmSwal(
        "You want to create a NCR?",
        "If you are sure, click on the confirm button"
      );
      if (!confirm.isConfirmed) return;
      this.addPreloader();
      try {
        const params = {
          client_account_id: item.client_account_id,
          round_letter_dispute_id: item.id,
          lead_id: item.lead_id,
        };

        await updateRequestService.storeRequestNcrForUpdate(params);

        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.refreshTable();
      }
    },
    openUrModalTracking(item) {
      this.showUrModalTracking = true;
      this.dataUr = item;
    },

    validateDate(date) {
      let currentDate = new Date();
      let enteredDate = new Date(date);
      let monthValidateNcr = 5;

      currentDate.setMonth(currentDate.getMonth() - monthValidateNcr);
      this.isValid = enteredDate >= currentDate;
      return this.isValid;
    },

    filterColumn(key, bool) {
      let field = this.inProcessFields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    async openModalSteps(item) {
      try {
        this.showModalSteps = true;
        this.rowData = item;
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  computed: {
    isDigital() {
      return this.$route.meta.is_digital;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCreditAnalyst() {
      return this.currentUser.role_id == 22;
    },
    ...mapState({
      totalRows: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.totalRows,
      paginate: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.paginate,
      startPage: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.startPage,
      toPage: ({ DebtSolutionDisputeResult }) =>
        DebtSolutionDisputeResult.toPage,
    }),
    visibleFields() {
      return this.inProcessFields.filter((field) => field.visible);
    },
    isAnalystLeader() {
      return this.currentUser.role_id == 21;
    },
    isSpecialist() {
      return this.moduleId === 28;
    },
    hasPermission() {
      return (
        this.isCeo || this.isChief || this.isSupervisor || this.isAnalystLeader
      );
    },
    ...mapGetters({
      gInProcessItems: "DebtSolutionDisputeResult/gInProcessItems",
      gProgram: "DebtSolutionDisputeResult/gProgram",
      currentUser: "auth/currentUser",
      totalRows: "DebtSolutionDisputeResult/gTotalRows",
      paginate: "DebtSolutionDisputeResult/gPaginate",
      startPage: "DebtSolutionDisputeResult/gStartPage",
      toPage: "DebtSolutionDisputeResult/gToPage",
      gOrder: "DebtSolutionDisputeResult/gOrder",
      getRefreshTable: "DebtSolutionDisputeResult/getRefreshTable",
    }),
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["disputeResultsList"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
